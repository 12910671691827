.back-arrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  color: $dark-purple;
  cursor: pointer;
  width: 100px;
  font-family: "Flama-Medium";
  color: #592d5f;
  img {
    max-width: 20px;
    width: 20px;
    margin-right: 10px;
  }

  :active {
    color: $dark-purple;
  }
}
