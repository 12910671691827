
.flex {
	display: flex;
	align-items: center;
  }
  
  .flex.fill {
	height: 100%;
  }
  
  .flex.center {
	justify-content: center;
  }

.row {
 
  display: flex;
  //padding-top: 50px;
    &.full {
      height: auto;
    }
  .col {
    position: relative;
    &.flex {
      display: flex;
      flex-direction: column;
    }
    &.content-col {
      flex-basis: calc(60% - 50px);
    }
    &.divider-col {
      flex-basis: 50px;
    }
    &.hotspot-col {
      flex-basis: calc(40%);
    }
    &.middle {
      justify-content: center;
      align-items: flex-end;
    }
    .row-reverse {
      flex-direction: row-reverse;
    }
    &.collapse {
  flex:0 0 50px;;
        padding: none !important;
    }
    &.center {
      justify-content: center;
      align-items: center;
    }
    &.right {
      align-items: flex-end;
      text-align: right;
    }

   
  }
 
}


.text-row {
  padding-top: 0px !important;
}
 
.inline-flex {
  display: inline-flex;
}
 .flex-row {
  flex-direction: row;
 }

 
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
 
.my-20 {
	margin-top: 20px;
}
.my-30 {
	margin-top: 30px;
}

// @media screen and (max-width: 662px) {
//   .row {
//     padding: 20px;
//     max-width: 100%;
//     .headshot-flex-mobile {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       .headshot {
//         margin: 0 auto;
//       }
//     }
//     &, .col {
//       .tertiary-title, .primary-title, .secondary-title {
//       text-align: left !important;
//       margin: unset;
//       }
//     }
//   }
// }

.row.full {
  width: 80%;
  margin: 0 auto;
}

.title-row {
  width: 85%;
}