:root {
  --isiHeight: 100vh;
  --isiHeader: 100vh;
  --isiContent: 100vh;

  .isi-container {
    perspective: 550px;
    transform-style: preserve-3d;
    position: fixed;
    height: 200px;
    width: 100vw;
  }
  .isi {
    &.closed {
      overflow: hidden;
    }
    &.open {
      overflow: scroll;
    }
   

    .col.left{
      min-width: 50% !important;
    }
    .col.right{
      float: right;
      width: 50%;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    &.open .second-column {
      margin-bottom: 10px;
    }
    height: 72%;
    opacity: 0;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    max-width: 90vw;
    font-size: 14px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: scroll;
    text-align: left;
    left: 0;
    right: 0;
    max-height: 90vh;
    font-family: "Flama-Book" !important;
    cursor: pointer;
    top: 0;
    position: fixed;
    &:hover {
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.7) !important;
    }

    .isi-header,
    .isi-content {
      padding: 10px;
      width: 100%;
    }
  
    .text-center {
      text-align: center;
    }
    
    & footer {
      padding: 20px 0;
    }
    

    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      margin-bottom: 0 !important;
    }
    footer {
      font-family: "NewsGothicMT";
      width: 100%;
      // background: green;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 10px !important;
      &.row {
        align-items: flex-end;
      }
      small {
        margin: unset;
        margin-bottom: 5px;
      }
      .novo {
        font-family: "NewsGothicMT-Bold";
      }
    }
    a {
      font-family: "Flama-Bold";
    }

    p {
      display: block;
    }

  }



  .columns-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .col{
      flex: 1;
    }
  }
}

.indications-row {
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}

:root {
  --hheight: 0px;
}

// @media only screen and (max-width: 900px) {

//   .columns-wrapper {
//     flex-direction: column;
//     .first-column {
//       max-width: 100%;
//     }

//     .indications-row {
//       display: block;
//     }
//   }

//   .reverse-columns {
//     flex-direction: column-reverse;
//   }
//   .isi footer {
//     display: flex;
//     align-items: flex-end;
//     margin-top: 50px;
//     margin-bottom: 20px;
//   }

//   @media screen and (max-width: 600px) {
//     .isi {
//       footer {
//         width: 100% !important;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         align-items: center;
//         .col {
//           width: 100% !important;
//           text-align: center;
//           align-items: center;
//           img {
//             margin-left: unset;
//             margin: 0 auto;
//           }
//         }
//       }
//     }
//   }
// }
// .s {
//   height: 100px;
//   width: 100px;
//   background: red;
//   border-radius: 50%;
//   display: inline-block;
// }

// .isi {
//   max-width: 90vw;
//   margin: 0 auto;
//   width: 80vw;
// }

.isi-link {
  text-decoration: underline;
  color: unset;
}

.bottom {
  &.isi {
    position: relative;
    height: unset;
    //max-width: unset;
    opacity: unset;
    max-height: unset;
    cursor: unset;
    z-index: 4;
    box-shadow: unset;
  }
}

.flex.isi-bottom-left-flex-align {
  align-items: flex-start;
}

:root .isi footer small.isi-bottom-left-margin {
  margin-left: 35px;
}

@media screen and (max-width: 1100px) {
  .flex.isi-bottom-left-flex-align {
    align-items: flex-start !important;
  }
}