@font-face {
    font-family: 'Arial-Black';
    src: url('../fonts/ArialBlack.ttf') format('truetype');
}

@font-face {
    font-family: 'Flama-Basic';
    src: url('../fonts/Flama-Basic.otf') format('opentype');
}

@font-face {
    font-family: 'Flama-Medium';
    src: url('../fonts/Flama-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Flama-Bold';
    src: url('../fonts/Flama-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Flama-Book';
    src: url('../fonts/Flama-Book.otf') format('opentype');
}

@font-face {
    font-family: 'Flama-SemiBold';
    src: url('../fonts/Flama-Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'NewsGothicMT';
    src: url('../fonts/NewsGothicMT.ttf') format('truetype');
}

@font-face {
    font-family: 'NewsGothicMT-Bold';
    src: url('../fonts/NewsGothicMTBold.ttf') format('truetype');
}

.flama-bold {
    font-family: 'Flama-Bold';
}

.flama-book {
    font-family: 'Flama-Book';
}

.flama-medium {
    font-family: 'Flama-Medium';
}

.flama-semi-bold {
    font-family: 'Flama-SemiBold';
}

.flama-basic {
    font-family: 'Flama-Basic';
}

.arial-black {
    font-family: 'Arial-Black';
}

