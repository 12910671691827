.plus-button-row {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    position: relative;
    margin-left: auto !important;
    cursor: pointer !important;
    font-family: "Flama-Book";
    margin: 10px 0;
    
    &.long {
      max-width: 425px;
    }
    img {
      margin-left: auto;
    }
  }