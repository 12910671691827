 
.header {
  max-width: $maxWidth;
  height: 100px;
  top: 0;
  width: 100%;;
  //margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.page-video .header {
  justify-content: space-between;
  margin-top: 45px;
  max-width: 100%;
}

.page-home .back-arrow {
  display: none;
}


 


 