$mm-color-base:#000 !default;
$mm-color-text:#fff !default;

.mm-cursor,.elastic-mouse-follower {
    $root:&;

    // height: 20px;
    // width: 20px;
    background: blue;
    position:fixed;
    z-index: 99999999999;;
position: fixed;
    direction:ltr;
    contain:layout style size;
 

    transition:opacity 0.3s, color 0.4s;

    &:before {
        content:"";
        position:fixed;
       z-index: 99999999999;;
        display:block;
        width: 50px;
        height: 50px;
        // transform:scale(0.2);

        background: red;;
        border-radius:50%;

        transition:transform 0.25s ease-in-out, opacity 0.1s;
    }

    &.mm-inverse {
        color:invert($mm-color-base);
    }

    &.mm-exclusion {
        @supports (mix-blend-mode:exclusion) {
            mix-blend-mode:exclusion;

            &:before {
                background:invert($mm-color-base);
            }
        }
    }

    &.mm-pointer {
        &:before {
            transform:scale(0.15);
        }
    }

    &.mm-text {
        &:before {
            opacity:0.85;
            transform:scale(1.7);
        }

        &.-active:before {
            transform:scale(1.6);
            transition-duration:0.2s;
        }
    }

    &.mm-icon {
        &:before {
            transform:scale(1.5);
        }

        &.-active:before {
            transform:scale(1.4);
        }
    }

    &.mm-hidden {
        &:before {
            transform:scale(0);
        }
    }

    &.mm-text {
        position:absolute;
        top:-18px;
        left:-18px;
        width:36px;
        height:36px;
        display:flex;
        align-items:center;
        justify-content:center;
        transform:scale(0) rotate(10deg);

        opacity:0;
        color:$mm-color-text;

        font-size:16px;
        line-height:20px;
        text-align:center;

        transition:opacity 0.4s, transform 0.3s;

        #{$root}.mm-text &, #{$root}.mm-icon & {
            opacity:1;
            transform:scale(1);
        }
    }

    &-media {
        position:absolute;
        width:400px;
        height:400px;
        margin:(-400px * 0.5) 0 0 (-400px * 0.5);

        img, video {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            border-radius:50%;

            @supports (object-fit:cover) {
                position:static;
                width:100%;
                height:100%;
                object-fit:cover;
                transform:translateZ(0);
            }
        }

        &.mm-box {
            position:relative;
            width:100%;
            height:100%;
            overflow:hidden;
            transform:scale(0) translateZ(0);
            padding:1px;

            opacity:0;
            border-radius:50%;

            transition:transform 0.35s, opacity 0.2s 0.2s;

            #{$root}.mm-media & {
                opacity:1;
                transform:scale(0.696);
                transition-duration:0.4s, 0.4s;
                transition-delay:0s, 0s;
            }
        }
    }
}