.hotspot-grid {
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 1/1.5;
  perspective: 550px;
  transform-style: preserve-3d;
  min-width: 250px;
  max-width: 452px;
}

.hotspot {
  hyphens: none;
  position: absolute;
  transform: translate3d(0, 0, 0px);
  text-align: left;
  color: $dark-grey;
  display: flex;
  font-family: "Flama-SemiBold";
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .hotspot-text,
  .hotspot-icon {
    transform: scale(0);
    opacity: 0;
  }

  &.peripheral-arthritis {
    flex-direction: row-reverse;
    .hotspot-text {
      order: 1;
      width: 83px;
      text-align: right;
    }
    .hotspot-icon-wrapper {
      order: 2;
    }
  }

  &.nail-psoriasis {
    flex-direction: column;
    justify-content: space-between;
    height: 44px;
    .hotspot-text {
      order: 1;
      width: 83px;
      text-align: left;
      margin-left: 60px;
    }
    .hotspot-icon-wrapper {
      order: 2;
    }
  }
  &.enthesitis {
    flex-direction: row-reverse;
    width: 200px;
  }
  &.axial-symptoms {
    flex-direction: row-reverse;
    width: 200px;
  }
  &.right,
  &.left {
    align-items: center;
    justify-content: space-between;

    &.top,
    &.bottom {
      align-items: space-between;
      justify-content: center;
      flex-direction: column;
    }
  }

  & .hotspot-canvas {
    border: 2px solid green;
    position: absolute;
    z-index: -1;
  }

  .page-video {
    .hotspot-icon-pink {
      height: 29px;
      width: 29px;
    }
  }
}
.hotspot-icon {
  aspect-ratio: 1/1;

  &.hotspot-icon-inline {
    vertical-align: middle;
    height: 48px;
    width: 48px;
    display: inline-block;
    position: relative;
    top: 15px;
  }
  &.hotspot-icon-wide-line {

    height: 48px;
    width: 120px;
  
  }
  &.hotspot-icon-pink {
    height: 48px;
    width: 48px;
  }
  &.hotspot-icon-pink.video {
    height: 30px;
    width: 30px;
  }
  &.hotspot-icon-grey {
    height: 56px;
    width: 56px;
  }
}

.hotspot-button {
  .hotspot-icon {
    width: 42px;
    height: 42px;
  }
  .hotspot-text {
    width: 100% !important;
    text-align: left !important;
  }
}

.hotspot-text {
  font-family: "Flama-Medium";
  font-size: 17px;
  text-transform: capitalize !important;
  hyphens: none;
  // background: blue;
  width: 100px;

  &.left {
    order: 1;
  }

  &.right {
    order: 2;
  }

  // &.bottom {
  //   transform: translate3d(-25%, calc(100% - 25px), 0) !important;
  // }

  // &.top {
  //   transform: translate3d(0, calc(100% + 25px + 10px), 0) !important;
  // }
}

// .hotspot-pill-icon img {
//   height: 30px;
//   aspect-ratio: 1/1;
//   width: 30px;
//   border-radius: 50%;
//   &:hover,
//   &:active {
//     cursor: pointer;
//   }
//   // &:hover {
//   // 	// transform: scale(1.2);
//   // 	animation: glow 1s ease-in-out infinite alternate;
//   // }
//   // &:active {
//   // 	transform: scale(.8);
//   // }
//   // transition: transform 0.3s ease-in-out;

//   img {
//     height: 100%;
//     width: 100%;
//   }
// }
// .hotspot-pill-icon img {
//   animation: none;
//   border: 4px solid white;
//   border-radius: 50%;
//   height: 47px;
//   width: 47px;
// }
@keyframes glow {
  to {
    box-shadow: 0px 0px 20px 10px $hot-pink;
  }
}

@keyframes hotspot {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($pinkrgb, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($pinkrgb, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($pinkrgb, 0);
  }
}

//  @media screen and (max-width: 750px) {
//  #woman-container {
// 		transform: scale(1) !important;
// 		width: 100%;
// 		max-width: 100%;
// 		margin-right: auto;
// 		position: static !important;
// 	}
// .row {
// 	flex-direction: column;
// 	padding: 20px !important;
// 	width: 100% !important;
// 	.col {
// 		display: flex;
// 		flex: 1;
// 	}
// }

// // 	// // .pill {
// // 	// // 	display: block !important;
// // 	// // 	height: 30px;
// // 	// // 	margin-right: 10px;

// // 	// // }
// // 	// .hotspot-parent {
// // 	// 	flex-direction: column;
// // 	// 	width: 100%;

// // 	// }

// // 	.hotspot {
// // 		display: inline-flex;
// // 		align-items: center;
// // 		position: static !important;
// // 		width: 100% !important;
// // 		padding: 10px;
// // 		height: auto !important;
// // 		text-align: left !important;
// // 		flex-direction: row !important;
// // 		background-color: white;
// // 		box-shadow: 0 0 5px #333;
// // 		border-radius: 25rem;
// // 		margin-bottom: 10px !important;
// // 		&:last-child {
// // 			border-bottom: none;
// // 		}
// // 	}
// // }
 
.woman {
  height: 100%;
  width: 100%;
}

.hotspot-button {
  align-items: center;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 25rem;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  background: white;

  span img {
    margin-right: 10px;
  }

  .hotspot-text,
  .hotspot-icon,
  img {
    transform: none !important;
    margin: 0 !important;
    padding: 0 !important;
    position: static !important;
  }
  .hotspot-text {
    margin-left: 10px !important;
  }
  .plus-wrapper {
    margin-left: auto;
  }
}

