.woman-canvas {
    position: relative;
    height: 800px;
    width: 600px;
    z-index: 9999999999;
}
 
.main-canvas {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
    canvas {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

#splash-canvas {
 
    width: 100vw;
    height: 100vh;

}
  
.cls-1{fill:none}.cls-2{fill:url(#linear-gradient)}.cls-4{fill:#df0e63}.cls-5{fill:#582c5f}
