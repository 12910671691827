.page {
  position: relative;
  display: flex;
  flex-direction: column;
  //max-width: $maxWidth;
  min-width: $minWidth;
  width: 100%;
  //margin: 0 auto !important;
  //padding: 20px 0;
  //background-color: white;
  min-height: 100vh;
}

.content {
  max-width: $maxWidth;
  margin: 0 auto 200px auto;
} 
 
.video-page-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 88%;
  margin: 0 0 0 auto;
  padding-top: 70px
}

.video-page-inner-wrapper .title-row {
  width: 72%;
}

@media screen and (max-width: 1100px) {
  .video-page-inner-wrapper {
    width: 95%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .video-page-inner-wrapper .title-row {
    width: unset;
  }
}