/*****************************
* BREAKPOINT VARIABLES
******************************/
$breakpoint1: 1100px;
$breakpoint2: 905px;
$breakpoint3: 768px;
$breakpoint4: 600px;
$breakpoint5: 450px;
$breakpoint6: 400px;

/*****************************
* SIZE VARIABLES
******************************/
$maxWidth: 1200px;
$minWidth: 320px;
$padding: 20px 50px;

/*****************************
* COLOR VARIABLES
******************************/
$pinkrgb: rgb(214, 14, 99);
$night-sky: #282c34;
$secondary-color: #61dafb;
$dark-purple: #592d5f;
$dark-grey: #414042;
$hot-pink: #d60e63;
$pink: pink;
$antique-white: antiquewhite;
$aquamarine: aquamarine;

 