@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";
@import "./mobile.scss";
@import "./typography.scss";
@import "./pages/video.scss";
@import "./pages/splash.scss";
@import "./hotspots.scss";
@import "./pages/home.scss";
@import "./modal.scss";
@import "./isi.scss";
@import "./magicmouse.scss";
@import "./fonts.scss";
@import "./page.scss";
@import "./header.scss";
@import "./canvas.scss";
@import "./layout.scss";
@import "./layers.scss";
@import "./animations.scss";
@import "./hotspotText.scss";
@import "./plus.scss";
@import "./close.scss";
@import "./headshot-row.scss";
@import "./plus-button-row.scss";
@import "./disclaimer.scss";
@import "./logo.scss";
@import "./background.scss";
@import "./backarrow.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  line-height: 108%;
}

html {
  font-family: "Flama-Book";
  // font-size: calc(15px + 0.390625vw) !
}

html,
body {
  min-height: 100vh;
  min-width: 320px;
  width: 100%;

  background: linear-gradient(to bottom,#f4f4f4, #b6b6b6);
  //background-color: #AEAEAE;
}

main {
  width: 100vw;
  // min-height: 100vh;

  margin: 0 auto;
}

.gs-dev-tools {
  position: fixed;
  bottom: 0;
}

.spacer {
  display: block;
  width: 100%;
  height: 20px;
}

.page-home .logo {
  cursor: unset;
}

.homepage-sup {
  top: -1em;
}

 
