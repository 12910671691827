.mm-cursor,.rodal, .rodal-mask, .rodal-dialog, .rodal-close {
    z-index: 12 !important ;;
}
.isi {
    z-index: 11;
}
.modal, .gs-dev-tools, .exit-button-row {
    z-index: 10;
}
#backdrop {
    z-index: 9;
}
.header {
    z-index: 8;
}

.hotspot, .scrollBar {
    z-index: 6;
}
canvas, .scrollTrack {
    z-index: 5;
}
.page , #smooth-wrapper, #smooth-content{
    z-index: 4;
}