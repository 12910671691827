// $breakpoint1: 1100px;
// $breakpoint2: 905px;
// $breakpoint3: 768px;
// $breakpoint4: 600px;
// $breakpoint5: 450px;

.desktop {
  display: block;
}
.mobile,
.hotspot-icon.mobile {
  display: none;
}

.text-right-desktop {
  text-align: right;
}

.push-right-desktop {
  margin-left: auto;
}

/***************************************
IF PAGE HEIGHT IS LESS THAN 500, THE CURVE HACK BG 
IS GOING TO BE TOO SMALL TO COVER WHAT IT NEEDS TO 
***************************************/
@media screen and (max-height: 500px) {
  .curve-hack {
    width: 75vw !important;
  }
}

@media screen and (max-height: 200px) {
  .curve-hack {
    width: 85vw !important;
  }
}
  
  

@media screen and (max-width: $breakpoint1) {
  $padding: 20px;
  .page {
    padding: $padding !important;
    background: white;
  }
  header.header {
    height: 65px;
  }
  
  .hide-breakpoint-1, #root .curve-hack, .new-curve {
    display: none;
  }

  .scrollTrack {
    right: 0 !important;
    height: 100vh !important;
    top: 0 !important;

    width: 4px;
    .scrollBar {
      width: 4px;
    }
  }
  .col.desktop,
  .desktop {
    display: none !important;
  }
  .row .flex.col.offset-2 {
    flex-grow: 1 !important;
  }
  /***************************
 * HEADER
 ***************************/

  /***************************
 * HOTSPOT GRID
 ***************************/
  .hotspot-grid {
    aspect-ratio: 0.5;
  }
  .page {
    .row.full {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center !important;
      max-width: 100% !important;
      min-width: none;
      .col {
        width: 100% !important;
        max-width: 100% !important;
        min-width: none;
      }
    }
  }

  .isi {
    max-width: 100vw !important;
    border-radius: 0 !important;
    font-size: 13px;

    .col.left, .col.right {
      margin: 0 !important;
      float: unset !important;
      min-width: 100% !important;
    }
  }

  .disclaimer {
    text-align: left;
  }
}

@media screen and (max-width: $breakpoint1) {
  .desktop,
  .woman,
  .hotspot-icon.desktop,
  .desktop-no-display,
  .no-header-display {
    display: none !important;
  }
  .mobile,
  .hotspot-icon.mobile {
    display: block;
  }
  /***************************
 * HEADER
 ***************************/
  .push-right-desktop {
    margin-left: unset;
  }
  .text-right-desktop {
    text-align: unset;
  }

  .text-left-mobile {
    text-align: left;
  }
  .header {
    height: 65px;
    
    // padding: 10px 20px;;
    .logo {
      position: unset;
      // max-width: unset;
    }

   
  }
  .page-video .header {
    margin-top: 0 !important;
  }
  .exit-button-row {
    .exit-button {
      height: 28px;
      width: 28px;
    }
  }
  .row {
    flex-direction: column;
  }
  .headshot-text {
    line-height: 25px !important;
  }
  .col,
  .col.middle {
    align-items: center !important;
  }
  .titles {
    text-align: left;
    align-items: flex-start !important;

    .title {
      max-width: 100%;
      width: 100%;
    }
  }
  .plus-button-row {
    margin: 10px auto !important;
  }
  .columns-wrapper {
    gap: none;
  }
  .left-content {
    width: 100% !important;
  }
  .second-column {
    width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
  }
  .isi {
    //max-height: 100vh !important;
  }
  .isi .click-here {
    font-size: 15px !important;
  }

  .isi .columns-wrapper {
    flex-direction: column !important;
  }
  .page-video .primary-title, .page-video .secondary-title , .page-video .tertiary-title {
    max-width: 100% !important;
  }

}

@media screen and (max-width: $breakpoint2) {
  header.header {
    height: 50px;
  }
  .isi .click-here {
    font-size: 14px !important;
  }
}
//768
@media screen and (max-width: $breakpoint3) {
  $padding: 20px;
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .rodal.guideline .rodal-dialog {
    height: 100% !important;
    width: 100% !important;
    padding: 20px;
  }

  .rodal.trial-modal .rodal-dialog {
    height: fit-content !important;
  }

  .isi {
    .isi-subtitle {
      font-size: 12px !important;
    }
    p {
      font-size: 10px !important;
    }
  }

  .isi {
    //max-height: 100vh !important;
    .isi-header {
      .isi-subtitle {
        font-size: 12px !important;
      }
      p {
        font-size: 10px !important;
      }
    }
    .click-here {
      font-size: 4px !important;
      line-height: 100% !important;
    }
    .close {
      font-size: 12px !important;
    }

    .close img {
      width: 12px;
      height: 12px;
    }

    .close .close-button {
      gap: 5px;
    }
   
    
    // footer.flex.row {
    //   flex-direction: column;
    //   align-items: center !important;

    //   img {
    //     margin: 20px auto !important;
    //   }
    // }
  }

  .page {
    padding: 15px !important;
  }
 
}

@media screen and (max-width: $breakpoint4) {
  .isi .click-here {
    font-size: 12px !important;
  }

  .page {
    padding: 10px !important;
  }
 
}
@media screen and (max-width: $breakpoint5) {
  .isi-click-here p {
    font-size: 8px !important;
  }
  .headshot-text {
    line-height: 20px !important;
  }
  .page {
    padding: 10px !important;
  }
  
  .close-button-row {
    position: relative !important;
    padding-bottom: 20px !important;
      
    }
}


@media screen and (max-width: $breakpoint6) {
  .isi-header {
    .isi-subtitle {
      font-size: 14px !important;
    }
    p {
      font-size: 12px !important;
    }
    .click-here {
      font-size: 8px !important;
    }
  }
}