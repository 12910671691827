.curve-hack {
  background-color: white;
  position: fixed;
  width: 50vw;
  right: 0;

  .curve {
    left: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50vw;
    background-color: white;
    right: 0;
  }
}

.curve-hack {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50vw;
  height: 100%;
  background: white;
  z-index: -1;
}
.curve {
  position: fixed;
  left: 15px;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: -1;
}

.new-curve {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  z-index: -1;
}

.curve-bg-extra {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-color: white;
  z-index: -1;
}