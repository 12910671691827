.headshot-row {
  .mease-headshot {
    width: 100%;
    max-width: 94px;
    aspect-ratio: 1/1;
    margin: 0 auto;
    display: inline-block;
  }
  .hotspot-icon {
    margin: auto 10px;
  }
}
