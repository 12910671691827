
.close{
    position: relative;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 20px;
    color: $dark-purple;
    font-family: "Flama-Medium";
    .close-button {
      display: inline-flex;
      gap: 10px;
    }
    img {
      width: 14px;
      height: 14px;
    }
   }