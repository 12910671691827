

  // .modal-close-button{
  //   position: relative;
  //   width: 100%;
  //   margin-bottom: 20px;
 
  //   z-index: 100;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   img {
  //     width: 15px;;
  //     margin-left: 15px;
  //   }
  //   .contract-icon{
  //     width: 18px;
  //     margin-left: 8px;
  //   }
  

  // .rodal-dialog {
    // aspect-ratio: 1/1;
    // width: 100% !important;
    // height: 100% !important;
    // max-width: 1100px !important;
    // max-height: 80% !important;
    // height: auto;
    // border-radius: 20px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: left;
    // padding: 20px;


    // .modal-wrapper {
      // display: flex;
      // flex-direction: column;
      // width: 100%;
      // justify-content: center;
      // padding: 20px;

      // p {
      //   margin: 5px 0;
      // }

      // sup {
      //   font-size: 60%;
      // }
    // }
//   }
// }

.rodal-mask {
	z-index: 9999999999 !important;
}
 
.rodal-dialog {
	font-size: 15px !important;
	line-height: 130% !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 999999999999999 !important;
	border-radius: 12px;
}
.guideline .rodal-dialog {
	height: 90vh !important;
	width: 90vw !important;
}
 
.rodal-mask {
  background:rgba(0,0,0,0.75);
  z-index: 9999999999 !important;
}
.rodal-close{
  display: none;
}
 
#guideline-smooth-wrapper {
  position: static !important;
}
.trial-modal .rodal-dialog {
  max-width: 550px !important;
  /* width: 100% !important; */
  /* margin: auto 20px auto 20px; */
  left: 20px;
  right: 20px !important;
  width: auto !important;
}

.guideline-bullet {
  padding-left: 10px;
}

.rodal-dialog * {
  line-height: 130% !important;
}