.page-splash {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    background: white;;
}

.base-image {
    width: 200px;
}

.page-splash .curve-hack {
    display: none;

}