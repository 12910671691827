.page-video{
  width: 100%;
  text-align: left;
  //margin-bottom: 100px;
  padding: 0 20px;
  padding-bottom: 120px;

  .primary-title {
    font-size: 39px;
    line-height: 120%;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .tertiary-title {
    max-width: 75vw
  }
  .secondary-title {
    font-size: 31px;
    line-height: 120%;
    margin-bottom: 10px;
    max-width: 75vw
  }
  video {
    display: block;
    height: auto !important;
    margin-bottom: 20px !important;
    width: 100% !important;
  }
  .hotspot-grid {
    position: relative;
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: unset;
  }
  .content-wrapper{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    :first-child{
      margin-right: 100px;
    }
 
  }

  .video-comment{
    max-width: 620px;


  
  }
}

.exit-button-row {
  background-color: #666666;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &.hidden {
    display: none;
    visibility: hidden;
  }

  .exit-button {
    height: 35px;
    width: 35px;
    cursor: pointer;
    aspect-ratio: 1/1;
  }
}
 .axial-symptoms .hotspot-text {
  width: 87px !important;
  text-align: right;
 }
.page-video .left {
  justify-content: center;
}
.page-video .content-row {
  padding-top: 20px;
}
@media screen and ( max-width: $breakpoint1) {
    .page-video {
      .row {
        flex-direction: column;
        .hotspot-grid {
          display: none;;
        }
        
        .col, video {
          width: 100% !important;
          max-width: 100% !important;
          min-width: none;
        }
      }
    }
}
@media only screen and (max-width: 900px) {
  .video-page-wrapper{
    .content-wrapper{
      justify-content: center;
      img{
        display: none;
      }
      :first-child{
        width: 100%;
        // max-width: 600px;
        margin: 0 auto;
      }
    }

    .video-comment{
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
  }
}
.video-comment {
  font-family: "Flama-Book";
  font-size: 13px;
  p {
    margin-bottom: 13px;
  }
}
.page-video .row {

  .left {
    width: 60vw;

    .tertiary-title {
      font-size: 18px;
      line-height: 100%;
    }
 
    
  }
}

.video-title {
  text-align: left;
 
}
.video-text {
  text-align: left !important;
  font-size: 16px;
  font-family: "Flama-Book";

  sup {
    font-size: 10px;
    vertical-align: super;
    margin-left: 2px;
    top: 0em;
  }
}
 small {
  display: block;
  margin: 15px auto;
 }
@media screen and (max-width: 800px) {
  #root, body, .page, main {
    background-color: white !important;;
    background: white !important;
  }
  .page-video {
    background: white;
  }
  .page-video .row {
    // padding: 0 20px;
    max-width: 100%;
    width: 100%;

    .col {
      width: 100%;
      margin: 0;
      max-width: 100%
    }
    .tertiary-title, .primary-title, .secondary-title {
      margin-left: 0px !important;
    }
    video {
      width: 100%;
      max-width: 100%;
      margin-top: 20px;
    }
  }
}