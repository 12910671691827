
.tertiary-title{
  color:   $dark-purple;
  font-family: 'Flama-Book';
  max-width: 471px;
  font-size: 0.813rem;
font-size: clamp(0.813rem, 0.5724074074074073rem + 1.2029629629629632vw, 1.625rem);


}

.primary-title{
  color: $dark-grey;
  font-family: "Flama-Bold";
  max-width: 650px;
  font-size: 1.938rem;
font-size: clamp(1.938rem, 1.4011111111111112rem + 2.6844444444444444vw, 3.75rem);
}
 
.secondary-title{
  color:  $dark-grey;
  font-size: 1.563rem;
font-size: clamp(1.563rem, 1.1001851851851852rem + 2.314074074074074vw, 3.125rem);

  font-family: 'Flama-Basic';
  sup{
    font-size: 50%;
  }
  span {
    color: $hot-pink;
  }
 
}

.headshot-text {
  color: $dark-purple;
  font-family: "Flama-Medium";
  font-size: 0.813rem;
font-size: clamp(0.813rem, 0.5724074074074073rem + 1.2029629629629632vw, 1.625rem);


  line-height: 2.325rem;
}
.disclaimer-text {
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.6759259259259259rem + 0.3703703703703704vw, 1rem);

  font-family: "Flama-Book";
  color: $dark-grey;
}
.body-text {
  font-size: 0.688rem;
  font-size: clamp(0.688rem, 0.5585185185185184rem + 0.6474074074074074vw, 1.125rem);

  font-family: "Flama-Book";
  color: $dark-grey;
}
.hotspot-icon-text {
  font-size: 0.813rem;
font-size: clamp(0.813rem, 0.6278148148148148rem + 0.9259259259259258vw, 1.438rem);
  font-family: "Flama-Medium";
  letter-spacing: -15px;
  color: $dark-grey;
}
.modal-text {
  font-size: 0.688rem;
font-size: clamp(0.688rem, 0.6139259259259259rem + 0.3703703703703704vw, 0.938rem);
  font-family: "Flama-Book";
  color: $dark-grey;
}
.close-button-text {
  font-size: 0.688rem;
font-size: clamp(0.688rem, 0.5214814814814814rem + 0.8325925925925928vw, 1.25rem);
  font-family: "Flama-Medium";
  color: $dark-grey;
}
.pink {
  color: $hot-pink;
}
.text-right-desktop {
  text-align: right;
}
.text-left-desktop {
  text-align: left;
}
.push-right-desktop {
  margin-left: auto;
}
.push-left-desktop {
  margin-right: auto;
}
@media screen and (max-width: $breakpoint1) {
  .push-left-desktop {
    margin-right: unset;
  }
  .push-right-desktop {
    margin-left: unset;
  }
  .text-right-desktop {
    text-align: unset;
  }
  .text-left-desktop {
    text-align: unset;
  }
  .text-left-mobile {
    text-align: left;
  }
  .text-center-mobile {
    text-align: center;
  }
}
 
.color-purple {
  color: $dark-purple;
}
.color-dark-grey {
  color: $dark-grey;
}

.isi-title{
  color: $dark-purple;
  font-size: 1rem;
  margin: 6px 0;
}

.isi-subtitle{
  color: $dark-purple;
  font-family: "Flama-Medium" !important;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 4px;
}

.top-spacing{
  margin-top: 6px;
}


.overview-circle{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  div{
    text-align: left;
    font-size: 1.2rem;
    max-width: 85px;
  }
  img{
    margin-right: 10px;
    max-width: 50px;
  }
}

// @media screen and (max-width: 1039px) {
//   .primary-title{
//     font-size: 5rem !important;
//   }
// }

.title {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: flex-end;
}

a { 
  color: $dark-purple
}

.pink {
  color: $hot-pink;
}