.page-home {
 
  .titles {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .col {

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    small {
      margin-top: 6px;
      display: block;
    }

    p {
      margin-left: auto;
    }
    .button-padding-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 40px;
    }
  
   

    .headshot-row {
      max-width: 450px;

      p.mobile {
        max-width: 450px;
        text-align: left;
        font-size: unset;
      }
      
      
    }
    .disclaimer {
      margin-bottom: 20px;
      max-width: 400px;
    }
 
  }

  .click-here {
    text-align: center;
    font-family: "Flama-Book" !important;
    margin: 10px auto;
    line-height: 21px;
    color: $dark-grey;
    a {
      color: $dark-purple;
      font-family: "Flama-Medium" !important;
    }
  }
  .divider {
    padding: 0 20px;
    height: 60vh;
    display: block;
    align-self: center;
    justify-self: center;
  }

  .font-20 {
    font-size: 20px;
  }
 
}

.curve-hack {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50vw;
  height: 100%;
  background: white;
  z-index: -1;
}
.curve {
  position: fixed;
  left: 0px;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: -1;
}
  
// @media screen and (max-width: 900px) {
  // .endpoint-wrapper {
  //   text-align: center !important;
  //   margin-bottom: 20px !important;
  // }
  // .hotspot-parent.col {
  //   flex-direction: column;
  //   .hotspot {
  //     position: relative;
  //     width: 100%;
  //     padding: 20px;
  //     margin: 0;
  //     border: 1px solid #ff0000;
  //   }
  // }
// }
 
@media screen and (max-width: 750px) {
  .row {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .select-a-hotspot,
  small.compensated,
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .disclaimer {
    text-align: center;
  }

  .headshot-flex {
    display: none;
  }
  .row .col.right {
    align-items: flex-start !important;
    text-align: left !important;
  }
  .select-a-hotspot {
    display: none;
  }
  .select-a-topic {
    display: block !important;
    text-align: left;
  }
  .tertiary-title,
  .primary-title,
  .secondary-title {
    margin: 0 auto;

    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    margin: 0 auto !important;
    text-align: left !important;
  }
  .headshot-flex-mobile {
    display: flex;
    max-width: 90%;
    margin-left: 0;
    font-size: 1rem;
    font-weight: 100;
    margin-bottom: 10px;
    align-items: center;
  }
}
.plus {
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
}


.click-here a {
  color: $dark-purple;
  text-decoration: underline;
}
