.plus {
    aspect-ratio: 1/1;
    cursor: pointer;
    border-radius: 50%;
    max-width: unset;
    & {
        height: 38px;
        width: 38px;
    }
}

.hotspot-button .plus {
    & {
        height: 35px;
        width: 35px;
    }
}