.logo {
  &,
  img.logo {
    width: auto;
    max-width: 200px;
    height: 100%;
    cursor: pointer;
  }
}

.header > .logo {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 18%;
  height: auto;
}

.page-video .logo {
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
  left: unset;
  height: auto;
}

.page-video .back-arrow {
  position: absolute;
  top: 20px;
  left: 10px;
}

 #root > .logo {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 5;
  height: unset;
 }

 @media screen and (min-width: 1101px) {
  .header {
    height: 20px;
  }
 }

 @media screen and (max-width: 1100px) {
  #root > .logo {
    display: none;
  }
  .header > .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
  }
  .row {
    padding-top: 10px;
  }
  .page-video .logo {
    max-width: 140px;
    position: unset;
  }
  .page-video .back-arrow {
    position: unset;
  }
 }

 .video #root > .logo {
  display: none;
 }

 @media screen and (max-width: 1650px) {
  .header > .logo {
    width: 16%;
  }
 }
 @media screen and (max-width: 1600px) {
  .header > .logo {
    width: 15%;
  }
 }
 @media screen and (max-width: 1500px) {
  .header > .logo {
    width: 14%;
  }
 }
 @media screen and (max-width: 1350px) {
  .header > .logo {
    width: 13%;
  }
 }
 @media screen and (max-width: 1250px) {
  .header > .logo {
    width: 12%;
  }
 }

 @media screen and (max-width: 1100px) {
  .header > .logo {
    width: 130px;
  }
 }